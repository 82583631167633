export default {
  namespaced: true,
  state: {
    baseURL: "https://panel.fmcs.com.sa/api/",
    mediaURL: "https://panel.fmcs.com.sa/uploads/",
  },
  // get the currant state value
  getters: { 
    mediaURL: state => state.mediaURL,
    baseURL: state => state.baseURL,
  },
  // use to perform un mutate or change states
  mutations: {

  }, 
  // use to perform un asynchronous tasks
  actions: { 

  },
};
