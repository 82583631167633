import axios from 'axios'
export default {
  namespaced: true,
  state: {
    serverResponse: null,
    serverLoading: false,
    serverError: false,
    ///////////////////// 
    confirmationResponse: null,
    confirmationLoading: false,
    confirmationError: false,
  },
  // get the currant state value
  getters: { 
    getServerResponse: state => state.serverResponse,
    getServerLoading: state => state.serverLoading,
    getServerError: state => state.serverError,
    ////////////////////////////////////////////////// 
    getConfirmationResponse: state => state.confirmationResponse,
    getConfirmationLoading: state => state.confirmationLoading,
    getConfirmationError: state => state.confirmationError,
  },
  // use to perform un mutate or change states
  mutations: {
    SET_SERVER_RESPONSE(state, response) {
      state.serverResponse = response;
    },
    SET_SERVER_ERROR(state, error) {
      state.serverError = error;
    },
    SET_SERVER_LOADING(state, loading) {
      state.serverLoading = loading;
    },
    /////////////////////////////////////////
    SET_CONFIRMATION_RESPONSE(state, response) {
      state.confirmationResponse = response;
    },
    SET_CONFIRMATION_ERROR(state, error) {
      state.confirmationError = error;
    },
    SET_CONFIRMATION_LOADING(state, loading) {
      state.confirmationLoading = loading;
    }
  }, 
  // use to perform un asynchronous tasks
  actions: { 
    async loadWallet({ commit }) {
      try {
        commit('SET_SERVER_LOADING', true);
        const response = await axios.get('wallet');
        commit('SET_SERVER_RESPONSE', response.data);
        commit('SET_SERVER_LOADING', false);
        commit('SET_SERVER_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_SERVER_ERROR', error.response.data.errors);
        commit('SET_SERVER_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
    async create({ commit }, formData) {
      try {
        commit('SET_CONFIRMATION_LOADING', true);
        const response = await axios.post('confirm_bank/create', formData);
        console.log(response.data);
        commit('SET_CONFIRMATION_RESPONSE', response.data);
        commit('SET_CONFIRMATION_LOADING', false);
        commit('SET_CONFIRMATION_ERROR',false);
        // console.log(response)
        return Promise.resolve(response.data)
      } catch (error) {
        // console.log(error)
        commit('SET_CONFIRMATION_ERROR', error.response.data.errors);
        commit('SET_CONFIRMATION_LOADING', false);
        return Promise.reject(error.response.data)
      }
    },
  },
};
